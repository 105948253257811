.menu-container {
  position: relative;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.arrow-left {
  left: 10px;
}

.arrow-right {
  right: 10px;
}

.react-horizontal-scrolling-menu--scroll-container {
  scrollbar-width: none;
}
